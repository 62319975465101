import {GetFromParseBySlug, FindFromParse, GetFromParse} from './parse';
import Parse from 'parse';
import {ModelYear} from './constants';
export const site = "hyperlite";

export const GetTvVideos = async (site) =>{
  const results = await FindFromParse("Videos",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results
}

export const GetYoutubeVideos = async (channelId) =>{
  const results = await FindFromParse("YoutubeVideos",[{method:"descending",value:'createdAt'},{method:"equalTo",key:"channelId",value:channelId},{method:"limit",value:1000}]);
  return results
}

export const GetVideos = async () =>{
  //const results = await FindFromParse("Videos",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return ['poo']
}

export const GetVideo = async () =>{
  const results = await FindFromParse("Videos",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results[0]
}

export const GetCollectionBySlug = async (slug) =>{
  const results = await GetFromParseBySlug("Videos",slug);
  return results
}

export const GetCollections = async () =>{
  const results = await FindFromParse("Collections",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results
}

export const GetRetailers = async () =>{
  const results = await FindFromParse("Retailers",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results
}

export const GetOnlineRetailers = async () =>{
  const results = await FindFromParse("Retailers",[{method:"containedIn",key:"site",value:site},{method:"limit",value:1000}]);
  return results
}

export const GetTeam = async () =>{
  const results = await FindFromParse("Team",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results
}

export const GetAthleteBySlug = async (slug) =>{
  const results = await GetFromParseBySlug("Videos",slug);
  return results
}

export const GetProductBySlug = async (slug,year) => {
  let prodModelYear = year ? year : ModelYear;
  const result = await GetFromParseBySlug("Products"+prodModelYear,slug);
  return result;
}

export const GetProductsBySlugs = async (slugs,year) => {
  console.log(slugs)
  let prodModelYear = year ? year : ModelYear;
  const results = await FindFromParse("Products"+prodModelYear,[{method:"containedIn",key:"slug",value:slugs},{method:"limit",value:1000},{method:"equalTo",key:"status",value:true}]);
  let resultDictionary = {};
  let orderedResults = [];
  for (let i = 0; i < results.length; i++) {
    console.log(results[i])
    resultDictionary[results[i].attributes.slug] = results[i]
  }
  for (let i = 0; i < slugs.length; i++) {
    let item = resultDictionary[slugs[i]];
    if(item){
      orderedResults.push(item)
    }
  }
  console.log(orderedResults)
  return orderedResults;
}

export const GetProductsBySite = async (site,year) => {
  let prodModelYear = year ? year : ModelYear;
  const results = await FindFromParse("Products"+prodModelYear,[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000},{method:"equalTo",key:"status",value:true}]);
  return results;
}
export const GetAthletesBySite = async (site) => {
  const results = await FindFromParse("Team"+prodModelYear,[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results;
}

export const GetRidersBySlugs = async (slugs,year) => {
  let prodModelYear = year ? year : ModelYear;
  const results = await FindFromParse("Team"+prodModelYear,[{method:"equalTo",key:"site",value:site},{method:"containedIn",key:"slug",value:slugs},{method:"limit",value:1000}]);
  return results;
}
export const GetCategoryBySlug = async (slug) => {
  const result = await GetFromParseBySlug("Categories",slug);
  return result;
}
export const GetProductsByCategory = async (id,year) => {
  let prodModelYear = year ? year : ModelYear;
  const results = await FindFromParse("Products"+prodModelYear,[{method:"equalTo",key:"category",value:id},{method:"limit",value:1000},{method:"ascending",value:'order'},{method:"equalTo",key:"status",value:true}]);
  return results;
}
export const GetProductsBySubCategory = async (id,year) => {
  let prodModelYear = year ? year : ModelYear;
  const results = await FindFromParse("Products"+prodModelYear,[{method:"equalTo"},{method:"equalTo",key:"subcategory",value:id},{method:"limit",value:1000},{method:"ascending",value:'order'},{method:"equalTo",key:"status",value:true}]);
  return results;
}
export const GetFiltersByCategory = async (category) => {
  const results = await FindFromParse("Filters",[{method:"equalTo",key:"parent",value:category},{method:"ascending",value:'order'}]);
  return results;
}
export const OrderProductsByPrice = async (products) => {
  var sortedProducts = products.sort(compare);
  return sortedProducts;
  function compare(a, b){
    if(b.attributes.content[0].price && a.attributes.content[0].price){
      return parseFloat(b.attributes.content[0].price.toString().replace(',','')) - parseFloat(a.attributes.content[0].price.toString().replace(',',''))
    } else{
      return false
    }
  }
}

export const GetAllProductLinks = async () => {
  const Query = new Parse.Query("ProductLinks");
  Query.limit(1000);
  let results = await Query.find();
  return results
}
export const GetLinksFromProduct = async (product) => {
  let results = [];
  let deduper = [];

  if(product.attributes.category){
    const categoryQuery = new Parse.Query("ProductLinks");
    categoryQuery.equalTo("category", product.attributes.category);
    let categoryResults = await categoryQuery.find();
    for (let i = 0; i < categoryResults.length; i++) {
      if(deduper.indexOf(categoryResults[i].id) == -1){
        deduper.push(categoryResults[i].id)
        results.push(categoryResults[i])
      }
    }
  }

  if(product.attributes.subcategory){
    const subcategoryQuery = new Parse.Query("ProductLinks");
    subcategoryQuery.equalTo("subcategory", product.attributes.subcategory);
    let subcategoryResults = await subcategoryQuery.find();
    for (let i = 0; i < subcategoryResults.length; i++) {
      if(deduper.indexOf(subcategoryResults[i].id) == -1){
        deduper.push(subcategoryResults[i].id)
        results.push(subcategoryResults[i])
      }
    }
  }

  if(product.attributes.slug){
    const slugQuery = new Parse.Query("ProductLinks");
    slugQuery.equalTo("slugs", product.attributes.slug);
    let slugResults = await slugQuery.find();
    for (let i = 0; i < slugResults.length; i++) {
      if(deduper.indexOf(slugResults[i].id) == -1){
        deduper.push(slugResults[i].id)
        results.push(slugResults[i])
      }
    }
  }
  console.log(results)
  if(results){
      return results
  }
}
export const GetCareers = async (site) => {
  const results = await FindFromParse("Careers",[{method:"equalTo",key:"site",value:site},{method:"limit",value:1000}]);
  return results;
}
export const GetCareer = async (id) => {
  const result = await GetFromParse("Careers",id);
  return result;
}
export const SortProductsByPrice = async (products, operator) => {
  var sortedProducts = products.sort(compare);
  return sortedProducts;

  function compare(a, b){
    let aPrice = parseFloat(a.attributes.content[0].price)
    let bPrice = parseFloat(b.attributes.content[0].price)
    if(operator == "descending"){
      return bPrice - aPrice;
    } else{
      return aPrice - bPrice;
    }
  }
}
