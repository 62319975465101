export const MobileBreakpoint = 800;
export const ParallaxValueFast = 10;
export const ParallaxValueSlow = 5;
export const HeaderHeight = 100;
export const MobileHeaderHeight = 70;

export const ModelYear = '2023';

export const ProductAssetUrl = 'https://assets.hosports.com/'+ModelYear+'/img/';
export const GeneralAssetUrl = 'https://assets.hosports.com/img/general/';
export const StaticAssetUrl = 'https://msdyn365-commerce-statics.commerce.dynamics.com/_msdyn365/_scnr/c9e16eb090c344e680fead88f3de5cf6/kent/';
export const YoutubeApiKey = 'AIzaSyAiSfh2074Zx0VSbfiL2lONFLNTyzjdDC8';
export const GoogleMapsApiKey = 'AIzaSyALfszc7nNuigg6VhhubzeayJekLnamUa0';

export const ParseCategoryIds = {
  waterskis:"ZpOClKJK9v",
  wakeboards:"tYeyYbLaJ5",
  waterskiBoots:"ADBkdDFX3z",
  wakeboardBoots:"z6DqA3NDcD",
  waterskiPackages:"xAB6cvA3w0",
  wakeboardPackages:"wCqgNSfcfb",
  kneeboards:"SjaYxIGxLt",
  wakesurfers:"m28WAffc8i",
  isummer:"GJdS7wzP34",
  iSup:"AhHrf28G4e",
  radFadPads:"3APCl7v5B5",
  iLand:"ZPD6vgr9Gb",
  iKayak:"GJdS7wzP34",
  waterskiVests:"bn0SXlvnBu",
  wetsuits:"Z5CnmwArFc",
  waterskiGloves:"ygjKtIDLEe",
  tubes:"mwqit82MLC",
  waterskiRopes:"34SHUzCEH7",
  waterskiApparel:"hakAsxlJJM",
  waterskiAccessories:"5lZuempwgl",
  marketing:"2pzdfnSk2X",
  wakeboardVests:"qtsMVDoo5y",
  wakeboardCompVests:"hIjHt8WDTN",
  wakeboardRopesAndHandles:"JXS0ddVAAR",
  wakeboardBallast:"lDtB8joWup",
  wakeboardAccessories:"RNY2pFfHac",
  foils:"GQkpe0fpO8",
  inflatableDocks:"ZPD6vgr9Gb"
}

export const SimpleProductCategories = [
  ParseCategoryIds.wakeboardAccessories,
  ParseCategoryIds.wakeboardBallast,
  ParseCategoryIds.wakeboardRopesAndHandles,
  ParseCategoryIds.marketing,
  ParseCategoryIds.waterskiApparel,
  ParseCategoryIds.waterskiApparel,
  ParseCategoryIds.waterskiRopes,
  ParseCategoryIds.waterskiAccessories
]

export const SimpleProductSubcategories = [
  //Varial Accessories
  "EYPAnD1L4c",
  //Ballast Accessories
  "pTjEjHjeaa",
  //iSummer accessories
  "f478Q8s5bg",
  //Tube accessories
  "wpEaSGJgND",
  //Boot accessories
  "eXWdLrLB67",
  //ski accessories
  "wWHALtqszI",
  //ski rope Accessories
  "wENbAACsgJ",
  //ski vest Accessories
  "yhfFK8PcVu",
  // promo accessories
  "nQT5BhvKdr",
  //Waterski Boat Accessories
  "d0YRfEtsAj"

]

export const HyperliteCollections = [
  {
    title:'Trever Maur Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/maur',
    image:ProductAssetUrl+'collection-maur-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-maur-banner-mobile.jpg'
  },
  {
    title:'Noah Flegel Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/flegel',
    image:ProductAssetUrl+'collection-noah-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-noah-banner-mobile.jpg'
  },
  {
    title:'Shaun Murray Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/murray',
    image:ProductAssetUrl+'collection-murray-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-murray-banner-mobile.jpg'
  },
  {
    title:'Rusty Malinoski Collection',
    preTitle:'2021 Hyperlite',
    link:'/collection/malinoski',
    button:'Explore',
    image:ProductAssetUrl+'collection-rusty-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-rusty-banner-mobile.jpg'
  },
  {
    title:'JD Webb Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/webb',
    image:ProductAssetUrl+'collection-webb-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-webb-banner-mobile.jpg'
  },
  {
    title:'Graeme Burress Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/burress',
    image:ProductAssetUrl+'collection-burress-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-burress-banner-mobile.jpg'
  },
  {
    title:'Alex Aulbach Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/aulbach',
    image:ProductAssetUrl+'collection-aulbach-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-aulbach-banner-mobile.jpg'
  },
  {
    title:'JB Oneill Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/oneill',
    image:ProductAssetUrl+'collection-oneill-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-oneill-banner-mobile.jpg'
  },
  {
    title:'Cody Hesse Collection',
    preTitle:'2021 Hyperlite',
    button:'Explore',
    link:'/collection/hesse',
    image:ProductAssetUrl+'collection-hesse-banner.jpg',
    mobileImage:ProductAssetUrl+'collection-hesse-banner-mobile.jpg'
  }
]

export const HyperliteOnlineRetailers = [
	{
		'name': 'RecID',
		'link':'https://www.recreationid.com/hyperlite/',
		'image': GeneralAssetUrl+'retailers/recid.jpg',
		'lang': 'en'
	},
	{
		'name': 'Scheels',
		'link':'https://www.scheels.com/c/water-activities/hyperlite',
		'image': GeneralAssetUrl+'retailers/scheels-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'Wake Boss',
		'link':'https://www.wakeboss.com/hyperlite/',
		'image': GeneralAssetUrl+'/images/wakeboss-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'Sun Sports',
		'link':'https://www.sunsportsmaine.com/hyperlite/',
		'image': GeneralAssetUrl+'retailers/sunsports-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'westmarine',
		'link':'https://www.westmarine.com/hyperlite',
		'image': GeneralAssetUrl+'retailers/westmarine.jpg',
		'lang': 'en'
	},
	{
		'name': 'idahowatersports',
		'link':'http://idahowatersports.com/product-tag/hyperlite/',
		'image': GeneralAssetUrl+'retailers/idahowatersports.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakeeffects',
		'link':'https://wakeeffects.com/product-category/brands/hyperlite/',
		'image': GeneralAssetUrl+'retailers/wakeeffects.jpg',
		'lang': 'en'
	},
	{
		'name': 'thehouse',
		'link':'https://www.the-house.com/search.html?keywords=hyperlite',
		'image': GeneralAssetUrl+'retailers/thehouse.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakehouse',
		'link':'http://www.wakehouse.com/brands/Hyperlite.html',
		'image': GeneralAssetUrl+'retailers/wakehouse.png',
		'lang': 'en'
	},
	{
		'name': 'boardco',
		'link': 'http://www.boardco.com/shopcontent.asp?contentid=96',
		'image': GeneralAssetUrl+'retailers/boardco.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakeboardscom',
		'link': 'http://www.wakeboards.com/Hyperlite-s/269.htm',
		'image': GeneralAssetUrl+'retailers/wakeboardscom.jpg',
		'lang': 'en'
	},
	{
		'name': 'miami',
		'link': 'http://www.miamiskinautiques.com/Hyperlite-Wakeboard-Equipment-s/1613.htm',
		'image': GeneralAssetUrl+'retailers/miami.jpg',
		'lang': 'en'
	},
	{
		'name': 'performance',
		'link': 'https://www.perfski.com/brands/hyperlite',
		'image': GeneralAssetUrl+'retailers/performance.jpg',
		'lang': 'en'
	},
	{
		'name': 'marineproducts',
		'link': 'http://www.marine-products.com/brands/wakeboarding/hyperlite-wakeboards.html',
		'image': GeneralAssetUrl+'retailers/marineproducts.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakesportscom',
		'link': 'https://www.wakesports.com/collections/hyperlite',
		'image': GeneralAssetUrl+'retailers/wakesports.jpg',
		'lang': 'en'
	},
	{
		'name': 'buywake',
		'link': 'https://www.buywake.com/brand/hyperlite',
		'image': GeneralAssetUrl+'retailers/buywake.jpg',
		'lang': 'en'
	},
	{
		'name': 'overtons',
		'link': 'http://www.overtons.com/Watersports/Hyperlite#filters?do=json&i=1&nav=1&q1=600&q2=Hyperlite&q3=Watersports&rank=rank2&sort=dateNew&x1=cat1&x2=brand&x3=t1&ckey=XI3Hti4oRHCjoSqmgSr71Q&cat_depth=1&matched_cat=600&is_refined=1',
		'image': GeneralAssetUrl+'retailers/overtons-old.jpg',
		'lang': 'en'
	},
	{
		'name': 'skipro',
		'link': 'https://skipro.com/shop-by-brand/hyperlite.html',
		'image': GeneralAssetUrl+'retailers/skipro.jpg',
		'lang': 'en'
	},
	{
		'name': 'm2',
		'link': 'http://www.m2sports.com/catalogsearch/result/?q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/m2.jpg',
		'lang': 'en'
	},
	{
		'name': 'gettommys',
		'link': 'http://gettommys.com',
		'image': GeneralAssetUrl+'retailers/tommys.jpg',
		'lang': 'en'
	},
	{
		'name': 'ridewatersports',
		'link': 'http://ridewatersports.com/brands/Hyperlite.html',
		'image': GeneralAssetUrl+'retailers/ridethewave.jpg',
		'lang': 'en'
	},
	{
		'name': 'wholesalemarine',
		'link': 'https://www.wholesalemarine.com/brands/Hyperlite-Wakeboards.html',
		'image': GeneralAssetUrl+'retailers/wholesalemarine-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'barts',
		'link': 'http://www.bartswatersports.com/brands.asp?M=Hyperlite',
		'image': GeneralAssetUrl+'retailers/barts.jpg',
		'lang': 'en'
	},
	{
		'name': 'evo',
		'link': 'https://www.evo.com/shop/hyperlite.aspx',
		'image': GeneralAssetUrl+'retailers/evo.jpg',
		'lang': 'en'
	},
	{
		'name': 'waterskiworld',
		'link': 'https://www.waterskiworld.com/wakeboards/hyperlite-wakeboards.html',
		'image': GeneralAssetUrl+'retailers/waterskiworld.jpg',
		'lang': 'en'
	},
	{
		'name': 'wileys',
		'link': 'https://www.wileyski.com/product-category/brands/brands-hyperlite/',
		'image': GeneralAssetUrl+'retailers/wileys.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakemakers',
		'link': 'https://www.wakemakers.com/hyperlite',
		'image': GeneralAssetUrl+'retailers/wakemakers-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'buywakeeuro',
		'link':'https://www.buywake.eu/hyperlite',
		'image': GeneralAssetUrl+'/images/buywakeeuro-map.jpg',
		'lang': 'de'
	},
	{
		'name': 'waketoolz',
		'link':' https://www.waketoolz.de/marken/hyperlite',
		'image': GeneralAssetUrl+'retailers/waketoolz.jpg',
		'lang': 'de'
	},
	{
		'name': 'warehouseone',
		'link':'https://www.warehouse-one.de/en/hyperlite/',
		'image': GeneralAssetUrl+'retailers/warehouseone.jpg',
		'lang': 'de'
	},
	{
		'name': 'wakeboardonline',
		'link':'https://wakeboardonline.nl/hyperlite-nl/?sort=p.price&order=DESC',
		'image': GeneralAssetUrl+'retailers/wakeboardonline.jpg',
		'lang': 'de'
	},
	{
		'name': 'buywakeeuro',
		'link':'https://www.buywake.eu/hyperlite',
		'image': GeneralAssetUrl+'/images/buywakeeuro-map.jpg',
		'lang': 'eu'
	},
	{
		'name': 'waketoolz',
		'link':' https://www.waketoolz.de/marken/hyperlite',
		'image': GeneralAssetUrl+'retailers/waketoolz.jpg',
		'lang': 'eu'
	},
	{
		'name': 'warehouseone',
		'link':'https://www.warehouse-one.de/en/hyperlite/',
		'image': GeneralAssetUrl+'retailers/warehouseone.jpg',
		'lang': 'eu'
	},
	{
		'name': 'wakeboardonline',
		'link':'https://wakeboardonline.nl/hyperlite-nl/?sort=p.price&order=DESC',
		'image': GeneralAssetUrl+'retailers/wakeboardonline.jpg',
		'lang': 'eu'
	},
	{
		'name': 'ollie',
		'link':'http://olliesport.com/kategorija-izdelka/znamke/hyperlite/ ',
		'image': GeneralAssetUrl+'/images/ollie.jpg',
		'lang': 'eu'
	},
	{
		'name': 'curve',
		'link':'https://curveboardsports.co.uk/search?q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/curve.jpg',
		'lang': 'eu'
	},
	{
		'name': 'kingofwatersports',
		'link':'https://www.kingofwatersports.com/search-results?search=Hyperlite&group=US',
		'image': GeneralAssetUrl+'retailers/kingofwatersports.jpg',
		'lang': 'eu'
	},
	{
		'name': 'robinhood',
		'link':'https://www.roho.co.uk/catalogsearch/result/index/?brand=2049&q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/robinhood.jpg',
		'lang': 'eu'
	},
	{
		'name': 's2as',
		'link':'https://www.s2as.com/brands/hyperlite',
		'image': GeneralAssetUrl+'retailers/s2as.jpg',
		'lang': 'eu'
	},
	{
		'name': 'tallington',
		'link':'https://www.tallingtonlakesproshop.com/brands-hyperlite.irc',
		'image': GeneralAssetUrl+'retailers/tallington.jpg',
		'lang': 'eu'
	},
	{
		'name': 'buywakeeuro',
		'link':'https://www.buywake.eu/hyperlite',
		'image': GeneralAssetUrl+'/images/buywakeeuro-map.jpg',
		'lang': 'eu'
	},
	{
		'name': 'waketoolz',
		'link':' https://www.waketoolz.de/marken/hyperlite',
		'image': GeneralAssetUrl+'retailers/waketoolz.jpg',
		'lang': 'eu'
	},
	{
		'name': 'curve',
		'link':'https://curveboardsports.co.uk/search?q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/curve.jpg',
		'lang': 'uk'
	},
	{
		'name': 'kingofwatersports',
		'link':'https://www.kingofwatersports.com/search-results?search=Hyperlite&group=US',
		'image': GeneralAssetUrl+'retailers/kingofwatersports.jpg',
		'lang': 'uk'
	},
	{
		'name': 'robinhood',
		'link':'https://www.roho.co.uk/catalogsearch/result/index/?brand=2049&q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/robinhood.jpg',
		'lang': 'uk'
	},
	{
		'name': 's2as',
		'link':'https://www.s2as.com/brands/hyperlite',
		'image': GeneralAssetUrl+'retailers/s2as.jpg',
		'lang': 'uk'
	},
	{
		'name': 'tallington',
		'link':'https://www.tallingtonlakesproshop.com/brands-hyperlite.irc',
		'image': GeneralAssetUrl+'retailers/tallington.jpg',
		'lang': 'uk'
	},
	{
		'name': 'curve',
		'link':'https://curveboardsports.co.uk/search?q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/curve.jpg',
		'lang': 'uk'
	},
	{
		'name': 'prowake',
		'link':'https://prowake.com.au/search?q=Hyperlite',
		'image': GeneralAssetUrl+'retailers/prowake.jpg',
		'lang': 'au'
	},
	{
		'name': 'skiforce',
		'link':'https://skiforce.com/brand/hyperlite/',
		'image': GeneralAssetUrl+'retailers/skiforce.jpg',
		'lang': 'au'
	},
	{
		'name': 'waterskiersconnection',
		'link':'https://www.waterskiersconnection.com.au/search/?query=hyperlite',
		'image': GeneralAssetUrl+'retailers/hlwaterskiersconnection-local.jpg',
		'lang': 'au'
	},
	{
		'name': 'elite',
		'link':'https://www.eliteskiboats.com.au/wakeboards#/manFilters=13&pageSize=1000&orderBy=10&pageNumber=1',
		'image': GeneralAssetUrl+'retailers/elite.jpg',
		'lang': 'au'
	},
	{
		'name': 'rapid',
		'link':'https://rapid.net.au/search?q=hyperlite',
		'image': GeneralAssetUrl+'retailers/rapid.jpg',
		'lang': 'au'
	},
	{
		'name': 'wilderness',
		'link':'https://www.wilderness.net.au/search.php?mode=search&page=1&keep_https=yes',
		'image': GeneralAssetUrl+'retailers/wilderness.jpg',
		'lang': 'au'
	}
]


export const HoOnlineRetailers = [
	{
		'name': 'Scheels',
		'link':'https://www.scheels.com/c/all/ho-sports',
		'image': GeneralAssetUrl+'retailers/hoscheels-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakeboss',
		'link':'https://www.wakeboss.com/ho-sports/',
		'image': GeneralAssetUrl+'images/wakeboss-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'redshift',
		'link':'https://www.redshiftwatersports.com/brand/ho-sports',
		'image': GeneralAssetUrl+'images/redshift-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'Wileys',
		'link':'https://www.wileyski.com/product-category/brands/brands-ho/',
		'image': GeneralAssetUrl+'images/wileys.jpg',
		'lang': 'en'
	},
	{
		'name': 'sunsports',
		'link':'https://www.sunsportsmaine.com/ho/',
		'image': GeneralAssetUrl+'images/sunsports-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'westmarine',
		'link':'https://www.westmarine.com/ho-sports',
		'image': GeneralAssetUrl+'images/westmarine.jpg',
		'lang': 'en'
	},
	{
		'name': 'idahowatersports',
		'link':'http://idahowatersports.com/product-tag/ho-sports/',
		'image': GeneralAssetUrl+'images/idahowatersports.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakeeffects',
		'link':'https://wakeeffects.com/product-category/brands/ho/',
		'image': GeneralAssetUrl+'images/wakeeffects.jpg',
		'lang': 'en'
	},
	{
		'name': 'thehouse',
		'link':'http://www.the-house.com/vendor-ho-store.html',
		'image': GeneralAssetUrl+'images/thehouse.jpg',
		'lang': 'en'
	},
	{
		'name': 'thewakehouse',
		'link':'http://www.wakehouse.com/brands/HO.html',
		'image': GeneralAssetUrl+'images/wakehouse.png',
		'lang': 'en'
	},
	{
		'name': 'bartswatersports',
		'link': 'http://www.bartswatersports.com/brands.asp?M=HO',
		'image': GeneralAssetUrl+'images/hobarts.jpg',
		'lang': 'en'
	},
	{
		'name': 'boardco',
		'link': 'http://www.boardco.com/shopcontent.asp?contentid=98',
		'image': GeneralAssetUrl+'images/logos/boardco.jpg',
		'lang': 'en'
	},
	{
		'name': 'waterskiscom',
		'link': 'http://www.waterskis.com/HO-Sports-s/266.htm',
		'image': GeneralAssetUrl+'images/waterskiscom.jpg',
		'lang': 'en'
	},
	{
		'name': 'miamiskinautique',
		'link': 'http://www.miamiskinautiques.com/HO-Waterski-Equipment-s/1616.htm',
		'image': GeneralAssetUrl+'images/miami.jpg',
		'lang': 'en'
	},
	{
		'name': 'performance',
		'link': 'http://www.perfski.com/brands/ho-skis',
		'image': GeneralAssetUrl+'images/performance.jpg',
		'lang': 'en'
	},
	{
		'name': 'marineproducts',
		'link': 'http://www.marine-products.com/ho-waterskis/',
		'image': GeneralAssetUrl+'images/homarineproducts.jpg',
		'lang': 'en'
	},
	{
		'name': 'wakesportscom',
		'link': 'https://www.wakesports.com/collections/ho-sports',
		'image': GeneralAssetUrl+'images/howakesports.jpg',
		'lang': 'en'
	},
	{
		'name': 'overtons',
		'link': 'http://www.overtons.com/Watersports/HO-Sports#filters?do=json&i=1&nav=1&q1=600&q2=HO-Sports&q3=Watersports&rank=rank2&sort=dateNew&x1=cat1&x2=brand&x3=t1&ckey=YuME18799H+kG6fdABtySA&cat_depth=1&matched_cat=600&is_refined=1',
		'image': GeneralAssetUrl+'images/overtons-old.jpg',
		'lang': 'en'
	},
	{
		'name': 'skipro',
		'link': 'https://skipro.com/shop-by-brand/ho-sports.html',
		'image': GeneralAssetUrl+'images/skipro.jpg',
		'lang': 'en'
	},
	{
		'name': 'm2',
		'link': 'http://www.m2sports.com/catalogsearch/result/?q=ho+skis',
		'image': GeneralAssetUrl+'images/m2.jpg',
		'lang': 'en'
	},
	{
		'name': 'gettommys',
		'link': 'http://gettommys.com/',
		'image': GeneralAssetUrl+'images/tommys.jpg',
		'lang': 'en'
	},
	{
		'name': 'ridewatersports',
		'link': 'http://ridewatersports.com/brands/HO-Sports.html',
		'image': GeneralAssetUrl+'images/ridethewave.jpg',
		'lang': 'en'
	},
	{
		'name': 'wholesalemarine',
		'link': 'https://www.wholesalemarine.com/brands/HO-Waterskis.html',
		'image': GeneralAssetUrl+'images/logos/howholesalemarine-local.jpg',
		'lang': 'en'
	},
	{
		'name': 'evo',
		'link': 'https://www.evo.com/shop/ho',
		'image': GeneralAssetUrl+'images/evo.jpg',
		'lang': 'en'
	},
	{
		'name': 'howaterskiworld',
		'link': 'https://www.waterskiworld.com/water_skis/ho_skis/ho_skis.html',
		'image': GeneralAssetUrl+'images/logos/howaterskiworld.jpg',
		'lang': 'en'
	}
];
